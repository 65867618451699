<template>
  <v-row justify="center">
    <v-dialog
      v-model="deleteContactWorksCouncilDialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Suppression de contact de comité d'entreprise</span>
        </v-card-title>
        <v-container>
          <v-card-text>
            <p v-if="successMessage">
              <v-alert
                text
                dense
                color="teal"
                border="left"
                type="success"
              >
                {{ successMessage }}
              </v-alert>
            </p>

            <span v-if="contactWorksCouncil">Etes-vous sûr de vouloir supprimer le contact de comité d'entreprise ?</span>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDeleteContactWorksCouncilDialog(false)"
          >
            Annuler
          </v-btn>

          <button
            type="button"
            :disabled="loading"
            class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1"
            @click="deleteContactWorksCouncil(contactWorksCouncil)"
          >
            <span class="v-btn__content">Valider</span>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import config from '../../../../config'

export default {
  name: 'DeleteContactWorksCouncilDialog',
  props: {
    contactWorksCouncil: {
      type: Object,
      default: () => {
        return {}
      }
    },
    deleteContactWorksCouncilDialogState: Boolean
  },
  data: () => ({
    successMessage: null,
    loading: false,
    errors: []
  }),
  methods: {
    deleteContactWorksCouncil (contactWorksCouncil) {
      this.loading = true

      Vue.prototype.$http
        .delete(config.apiUrl + '/api/works-council/contacts/' + contactWorksCouncil.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 204) {
            this.successMessage = 'Le contact de comité d\'entreprise a été supprimé.'
            this.errors = []
            this.closeDeleteContactWorksCouncilDialog(true)
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false

          const response = e.response

          if (response && response.status === 400) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response.status === 500) {
            this.errors.push({
              title: 'Une erreur est intervenue lors de la suppression du contact de comité d\'entreprise.'
            })
          }
        })
    },
    closeDeleteContactWorksCouncilDialog (deleteState) {
      this.$emit('closeDeleteContactWorksCouncilDialog', deleteState)
    }
  }
}
</script>
