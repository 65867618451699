<template>
  <v-col cols="12">
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card>
      <v-card-title>Contacts CE</v-card-title>

      <v-row class="mb-5">
        <v-col
          class="tags-search ml-5"
          cols="2"
          sm="2"
        >
          <vue-tags-input
            v-model="tag"
            :tags="tags"
            placeholder="Recherche"
            @tags-changed="newTags => {
              tags = newTags
              search()
            }"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-btn
            class="mt-1"
            small
            color="info"
            @click="search()"
          >
            <v-icon
              small
              class="mr-1"
            >
              {{ icons.mdiDatabaseSearchOutline }}
            </v-icon>
            <i class="fas fa-search"></i>
            Rechercher
          </v-btn>
          <v-btn
            small
            color="info"
            class="ml-2 mt-1"
            @click="reset()"
          >
            <v-icon
              small
              class="mr-1"
            >
              {{ icons.mdiBackupRestore }}
            </v-icon>
            Réinitialisation
          </v-btn>
        </v-col>
      </v-row>

      <div class="clear"></div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Entreprise
              </th>
              <th class="text-center text-uppercase">
                Tél
              </th>
              <th class="text-center text-uppercase">
                Email
              </th>
              <th class="text-center text-uppercase">
                Date de prise de contact
              </th>
              <th class="text-center text-uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(contactWorksCouncil, contactWorksCouncilIndex) in contactWorksCouncils"
              :key="'e' + contactWorksCouncilIndex"
            >
              <td class="font-weight-bold">
                {{ contactWorksCouncil.company }}
              </td>
              <td class="text-center">
                {{ contactWorksCouncil.phone }}
              </td>
              <td class="text-center">
                {{ contactWorksCouncil.email }}
              </td>
              <td class="text-center">
                {{ formatDate(contactWorksCouncil.createdAt) }}
              </td>
              <td class="text-center">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      :to="{ name: 'contactWorksCouncilDetails', params: { contactWorksCouncilId: contactWorksCouncil.id }}"
                    >
                      <v-icon dark>
                        {{ icons.mdiEye }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Consulter</span>
                </v-tooltip>

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      color="error"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      @click="openDeleteContactWorksCouncilDialog(contactWorksCouncil)"
                    >
                      <v-icon dark>
                        {{ icons.mdiTrashCan }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!contactWorksCouncils.length">
            <tr>
              <td>
                <span v-if="loading === false">Aucun éléments.</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <DeleteContactWorksCouncilDialog
        :key="Date.now()"
        :delete-contact-works-council-dialog-state="deleteContactWorksCouncilDialogState"
        :contact-works-council="contactWorksCouncilToDelete"
        @closeDeleteContactWorksCouncilDialog="closeDeleteContactWorksCouncilDialog($event)"
      ></DeleteContactWorksCouncilDialog>
    </v-card>
  </v-col>
</template>

<script>
import { mdiPlay, mdiPen, mdiEye, mdiTrashCan, mdiBackupRestore, mdiDatabaseSearchOutline } from '@mdi/js'
import Vue from 'vue'
import DeleteContactWorksCouncilDialog from '@/components/Dialog/ContactDialog/WorksCouncil/DeleteContactWorksCouncilDialog'
import config from '../../../config'
import Loader from '@/components/Common/Loader'
import VueTagsInput from '@johmun/vue-tags-input'

export default {
  components: {
    DeleteContactWorksCouncilDialog,
    Loader,
    VueTagsInput
  },
  data: () => ({
    nextPage: 1,
    tag: '',
    tags: [],
    contactWorksCouncils: [],
    contactWorksCouncilToDelete: null,
    deleteContactWorksCouncilDialogState: false,
    loading: false,
    icons: {
      mdiPlay,
      mdiPen,
      mdiEye,
      mdiTrashCan,
      mdiBackupRestore,
      mdiDatabaseSearchOutline
    }
  }),
  computed: {
    isHandlingContactWorksCouncilScroll () {
      return this.$store.getters.isHandlingContactWorksCouncilScroll
    }
  },
  created () {
    this.getWorksCouncils()

    window.removeEventListener('scroll', this.handleScroll)

    setTimeout(() => {
      window.addEventListener('scroll', this.handleScroll)
    }, 1000)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const wrapperOffsetHeight = document.querySelector('.v-application--wrap').offsetHeight
      const bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === wrapperOffsetHeight

      if (bottomOfWindow && !this.isHandlingContactWorksCouncilScroll) {
        this.getWorksCouncils()
      }
    },
    getWorksCouncils () {
      this.loading = true

      const params = {
        page: this.nextPage
      }

      const terms = []

      for (let i = 0; i < this.tags.length; i++) {
        terms.push(this.tags[i].text)
      }

      if (terms.length) {
        params.terms = terms.join(',')
      }

      this.$store.commit('setHandlingContactWorksCouncilScroll', true)

      Vue.prototype.$http
        .get(config.apiUrl + '/api/works-council/contacts', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const contactWorksCouncils = response.data

            if (contactWorksCouncils && contactWorksCouncils.length) {
              this.contactWorksCouncils.push(...contactWorksCouncils)

              this.nextPage = this.nextPage + 1
            }
          }

          this.loading = false
          this.$store.commit('setHandlingContactWorksCouncilScroll', false)
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
          this.$store.commit('setHandlingContactWorksCouncilScroll', false)
        })
    },
    search () {
      const params = {
        page: 1
      }

      const terms = []

      for (let i = 0; i < this.tags.length; i++) {
        terms.push(this.tags[i].text)
      }

      if (terms.length) {
        params.terms = terms.join(',')
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/api/works-council/contacts', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            this.contactWorksCouncils = response.data

            this.nextPage = 2
          }
        })
        .catch(() => {
        })
    },
    reset () {
      this.tag = ''
      this.tags = []

      this.search()
    },
    openDeleteContactWorksCouncilDialog (contactWorksCouncil) {
      this.deleteContactWorksCouncilDialogState = true
      this.contactWorksCouncilToDelete = contactWorksCouncil
    },
    closeDeleteContactWorksCouncilDialog (deleteState) {
      if (deleteState === true) {
        this.contactWorksCouncils = this.contactWorksCouncils.filter(element => element.id !== this.contactWorksCouncilToDelete.id)
      }

      this.deleteContactWorksCouncilDialogState = false
    },
    formatDate (currentDate) {
      if (currentDate) {
        const timestamp = Date.parse(currentDate)
        const date = new Date(timestamp)
        let day = date.getDate()
        let month = date.getMonth() + 1

        day = (day < 10 ? '0' : '') + day
        month = (month < 10 ? '0' : '') + month

        return day + '/' + month + '/' + date.getFullYear()
      }

      return currentDate
    }
  }
}
</script>

